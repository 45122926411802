.btn-add-product {
   color: #fff;
   background: rgba(57, 169, 61, 1);
   background: linear-gradient(130deg, rgba(57, 169, 61, 1) 28%, rgb(9, 146, 4) 77%);
   font-size: 18px;
   padding: 5px;
   font-weight: 800;
}

.btn-cancel {
   color: #fff;
   background: rgb(169, 57, 57);
   background: linear-gradient(130deg, rgba(57, 169, 61, 1) 28%, rgb(9, 146, 4) 77%);
   font-size: 18px;
   padding: 5px;
   font-weight: 800;
}

.text-subtitel-order-ecommerce {

   font-size: 13px;

}

.fake-div {

   height: 6px;
}


.bg-header-payments {
   margin: 4px 0px;
   width: 100%;
   color: #fff;
   background: #005C97;
   background: -webkit-linear-gradient(to right, #363795, #005C97);
   background: linear-gradient(to right, #363795, #005C97);

   border-radius: 15px;
}


.bg-header-payments h2 {
   color: #fff;
   font-size: 30px;
   font-weight: 700;
   letter-spacing: 2px;
}

/*Title Modal Payments  */
.list-group-item-check-pays {
   color: black;
   font-weight: 400;
}

.list-group-item-check-pays:checked+.list-group-item {
   color: #007bff;
   width: 100%;
   border: 2px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-pre-orders-inputs-pays input[type="radio"] {
   display: none;
}

.list-pre-orders-inputs-pays input[type="radio"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #ffffff00;
   margin-right: 10px;

}

.list-pre-orders-inputs-pays input[type="radio"]:checked+label:before {
   background-color: #007bff;
   transition: background-color 0.5s;
   animation: jello-vertical 0.4s linear both;
   animation-iteration-count: 1;
   -webkit-animation: jello-vertical 0.4s linear both;
   -webkit-transition: background-color 0.5s;
   -moz-transition: background-color 0.5s;
   -ms-transition: background-color 0.5s;
   -o-transition: background-color 0.5s;
}

/* Establecer el contenido del icono */
.list-pre-orders-inputs-pays input[type="radio"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (✔) */
   font-size: 14px;
   color: rgb(255, 255, 255);
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-pre-orders-inputs-pays label {
   position: relative;
   padding-left: 0px;
}

.list-pre-orders-inputs-pays input[type="radio"]+label:before {
   position: absolute;
   right: -9px;
   top: 1%;
}

.list-group-item {
   background-color: var(--blanco);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}

.list-pre-orders-inputs-payss>img {
   background-color: #20b852;
   ;

}

.list-pre-orders-inputs-pays:not(:checked)+.list-group-item img {
   background-color: #20b85352;
}

.list-pre-orders-inputs-pays:checked+.list-group-item img {
   background-color: #20b852;
}

.img-payment {
   color: #fff;
   background: #005C97;
   background: -webkit-linear-gradient(to right, #363795, #005C97);
   background: linear-gradient(to right, #363795, #005C97);

}



