.bg-header {
   background-color: rgba(0, 0, 0, 0.7);
   padding: 1px;
   margin-top: 10px;
}

.btn-header-ecommerce {
   color: #ffffff;
   font-size: 15px;
   border: 1px solid #a09e9e50;
}

.btn-header-ecommerce:focus {
   color: #fff;
   padding: 5px 10px;
   margin: 2px 4px;
   background: #055aaf;
   background: -webkit-linear-gradient(to top, #0357be, #0baad6);
   background: linear-gradient(to top, #0357be, #0baad6)
}

.btn-header-ecommerce:active {
   color: #fff;
   background: #055aaf;
   background: -webkit-linear-gradient(to top, #0357be, #0baad6);
   background: linear-gradient(to top, #0357be, #0baad6);
   transform: scale(0.85);
   -webkit-transform: scale(0.85);
   -moz-transform: scale(0.85);
   -ms-transform: scale(0.85);
   -o-transform: scale(0.85);
}

.icon-header-ecommerce {
   font-size: 22px;
}

.img-ecommerce-header {
   width: 100px;
   height: 50px;
   object-fit: cover;
}

.text-store-header {
   font-size: 15px;
   color: rgba(0, 0, 0, 0.486);
}

.btn-header-ecommerce small {
   font-size: 10px;
}

.rotate-45 {

   transform: rotate(45deg);
}