@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

/* Title: General layout */
.ecomerce-container-0 {
   height: 100vh;
   background-color: #000000;
   width: 30%;
   position: relative;
}


@media (max-width: 912px) {
   .ecomerce-container-0 {
      height: 100vh;
      background-color: #000000;
      width: 100%;
      position: relative;
   }
}

.ecomerce-container-1 {
   color: #000000;
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
   flex-direction: column;
   flex-wrap: nowrap;
   align-content: flex-start;
   /* background-color: #F6F9FA; */
   height: 100% !important;
   gap: 10px;
}

.ecomerce-container-1>section {
   margin: 20px 4px;
}

.ecomerce-container-section-0 {
   /* flex:3 1 auto; */
   order: 0;
   flex-grow: 1;
   overflow: auto;
}

.ecomerce-container-section-0>span {
   margin-top: 20px;
}

.container-card-favorite {
   margin-bottom: 150px;
}

.subtitle-ecommerce {
   font-weight: 800;
   font-size: 1.3rem;
   font-family: "Mulish", sans-serif;
   font-style: normal;
   letter-spacing: .1px;

   padding: 2px 0px;
   margin-top: 40px;
   margin-left: 7px;



   /* background: linear-gradient(to top, #0072ff, #00c6ff);
   background-clip: text;
   -webkit-text-fill-color: transparent;
   user-select: text; */
}



.img-carrusel-ecommerce {
   /* width: 98%; */
   height: 200px;
   object-fit: cover;
   margin: auto;
   border-radius: 15px;
}

/* Carts Categories home  */

.img-favorite-menu {
   width: 80px;
   height: 80px;
   margin: 0px 5px;
   object-fit: cover;
}

.text-favorite-menu {
   font-size: 13px;
   font-weight: 800;
   width: 67px;
   display: inline-block;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;

}

.text-value-favorite-menu {
   display: block;
   font-size: 14px;
   font-weight: 900;
   color: green;
   letter-spacing: 1px;
   font-variant-numeric: tabular-nums;

}

.card-favorite-menu {
   border: 1px solid #a09e9e;
}

.card-favorite-menu:hover {
   border: 1px solid blue;

}


.card-categories {
   width: 100%;
   border: 1px solid rgba(220, 220, 220, 0);


}

.img-categories-menu {
   border: 1px solid rgba(220, 220, 220, 0.195);

   width: 70px;
   height: 70px;
   object-fit: cover;

}

.card-categories-text {

   font-size: 12px;
   width: 70px;
   display: block;
   font-weight: 600;
}


.img-favorite-menu {
   width: 100%;
   height: 150px;

   object-fit: cover;
}



.card-recomend-title {
   font-size: 17px;
   display: block;
   font-weight: 600;
}


.card-recomend-description {
   font-size: 12px;
   font-weight: 500;
   color: #a09e9e;
   display: -webkit-box;
   box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   line-clamp: 2;
   line-height: 1.5;
   max-height: calc(1.5em * 2);
}


.card-recomend-ranking {
   font-size: 12px;
   font-weight: 500;
   color: #a09e9e;
   display: -webkit-box;
   box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   line-clamp: 2;
   line-height: 1.5;
   max-height: calc(1.5em * 2);
}

.badge-ranking {
   color: #000000;
   background-color: #ffe603;


}

.badge-ranking-position {

   right: 10%;
   bottom: 6%;
}

.card-recomend-price {
   font-size: 18px;
   display: block;
   font-weight: 700;

}