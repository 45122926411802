
/* Cards branchs  */
.ecommerce-branchs-card{
   border: 1px solid #adb5bd85;
   
   }

   .img-ecommerce-branchs-card {
      width: 100px;
      height: 100px;
      object-fit: contain;
   }
   
   
   .title-ecommerce-branchs-card {
      font-size: 18px;
      width: 100px;
      font-weight: 800;
   }
   .description-ecommerce-branchs-card {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      max-height: 3em; 
      overflow: hidden;
      position: relative;      
   }
   
   .value-ecommerce-branchs-card {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
      letter-spacing: 1px;
   }