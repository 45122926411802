.btn-message {
    background-color: #ffffff;
    font-size: 15px;
    border: 1px solid #a09e9e50;
}

.btn-success {
    background-color: #09d102;
    font-size: 15px;
    border: 1px solid #a09e9e50;
}

.bg-success-msg{
    background-color: #02b300;
}

.text-success-msg{

    color: #ffffff;
}

.bg-danger-msg{
    background-color: #d30e0e;
}


.btn-danger {
    background-color: #d30e0e;
    font-size: 15px;
    border: 1px solid #a09e9e50;
}


.bg-default-msg{
    background-color: #175db3;
}

.btn-default {
    background-color: #175db3;
    font-size: 15px;
    border: 1px solid #a09e9e50;
}


.btn-message-icon {
    color: #ffffff;
    /* background: linear-gradient(to top, #ff730a, #ffd8a5);
    background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-size: 20px;
}


.btn-message-title {
    color: #ffffff;
    font-size: 15px;
}

.btn-message-subtitle {
    color: #ffffff;
    font-size: 12px;
}

/* Estilos del fondo del modal */
.modal-overlay-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Estilos del contenedor del modal */
.modal-content-message {
    width: 95%;
    height: auto;
    background-color: white;
    border-radius: 15px;
    position: relative;
    /* display: flex;
  justify-content: center;
  align-items: center; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease;
}

/* Estilos del botón de cierre */
.close-button-message {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.2s ease;
}

.close-button-message:hover {
    color: red;
}

/* Animación para el modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}


.modal-content-message-message {
    width: 100%;
    height: 520px;
}

@media (width <=480px) {
    .modal-content-message-message {
        width: 100%;
        height: 90%;
    }
}

@media (width <=380px) {
    .modal-content-message-message {
        width: 100%;
        height: 88%;
    }
}
