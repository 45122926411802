.banner-brand {
    width: 100%;
    display: block;
    /* background-image: url("./img/bg-food.png"); */
    background:
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, rgba(227, 227, 227, 0.16) 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, rgba(227, 227, 227, 0.16) 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, rgba(227, 227, 227, 0.16) 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, rgba(227, 227, 227, 0.16) 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        repeating-conic-gradient(#ffffff 0 25%, #0000 0 50%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(#0000 66%, rgba(227, 227, 227, 0.16) 68% 70%, #0000 72%) 0 calc(40px/2)/40px 40px #ffffff;
    /* background-repeat: no-repeat;
    object-fit: cover;
    background-position:unset; */
}

.banner-brand-logo {
margin-top: 20px;
    width: 120px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;

}


.banner-content {

    padding: 5px 10px;
}

.brand-name {

    background: linear-gradient(to top, #f75948, #fc9264);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-subtitle {
    font-size: 15px;
    font-weight: 600;

}

.banner-slogan {
    font-size: 25px;
    font-weight: 800;
}

.banner-description {
    font-weight: 600;
    font-size: 12px;
}



.banner-feat {
    align-content: center;
    text-align: center;
    overflow: hidden;
    font-size: 16px;

}


.banner-feat-img {
    width: 100px;
    height: 100px;

}

/*Banners COlors  */

.banner-green {

    background:
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #4ea677 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #4ea677 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #4ea677 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #4ea677 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        repeating-conic-gradient(#5ca97f 0 25%, #0000 0 50%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(#0000 66%, #4ea677 68% 70%, #0000 72%) 0 calc(40px/2)/40px 40px #5ca97f;
}

.banner-yellow {

    background:
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #ffc430 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #ffc430 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #ffc430 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #ffc430 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        repeating-conic-gradient(#fdca4a 0 25%, #0000 0 50%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(#0000 66%, #ffc430 68% 70%, #0000 72%) 0 calc(40px/2)/40px 40px #fdca4a;
}

.banner-blue {

    background:
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, rgba(0, 172, 251, 0.86) 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, rgba(0, 172, 251, 0.86) 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, rgba(0, 172, 251, 0.86) 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, rgba(0, 172, 251, 0.86) 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        repeating-conic-gradient(#00b0ff 0 25%, #0000 0 50%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(#0000 66%, rgba(0, 172, 251, 0.86) 68% 70%, #0000 72%) 0 calc(40px/2)/40px 40px #00b0ff;
}

.banner-red {

    background:
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #f0481f 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #f0481f 68% 70%, #0000 72%) 40px 40px/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #f0481f 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #f0481f 68% 70%, #0000 72%) 0 0/calc(2*40px) calc(2*40px),
        repeating-conic-gradient(#f05c37 0 25%, #0000 0 50%) 0 0/calc(2*40px) calc(2*40px),
        radial-gradient(#0000 66%, #f0481f 68% 70%, #0000 72%) 0 calc(40px/2)/40px 40px #f05c37;
}