.categories-menu {
   margin: 0;
   width: 79px;
   white-space: unset;

   /* overflow: hidden;

   position: relative; */
}



/* .text-categories-menu {
   font-size: 12px;
   width: 67px;
   display: inline-block;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
} */

.text-categories-menu {
   font-size: 12px;

}

/* Title Radio-categories */
.list-group-item-check {
   color: black;
   font-weight: 400;
}

.list-group-item-check:checked+.list-group-item {
   color: #007bff;
   width: 80px;
   border: 2px solid #007bff;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.list-pre-orders-inputs input[type="radio-categories"] {
   display: none;
}

.list-pre-orders-inputs input[type="radio-categories"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #ffffff00;
   margin-right: 10px;

}

.list-pre-orders-inputs input[type="radio-categories"]:checked+label:before {
   background-color: #007bff;
   transition: background-color 0.5s;
   animation: jello-vertical 0.4s linear both;
   animation-iteration-count: 1;
   -webkit-animation: jello-vertical 0.4s linear both;
   -webkit-transition: background-color 0.5s;
   -moz-transition: background-color 0.5s;
   -ms-transition: background-color 0.5s;
   -o-transition: background-color 0.5s;
}


.items-product-menu {


   padding-top: 20px;
   padding-bottom: 190px;
}



@keyframes rotate-scale-down {
   0% {
      transform: scale(1) rotateZ(0);
      -webkit-transform: scale(1) rotateZ(0);
      -moz-transform: scale(1) rotateZ(0);
      -ms-transform: scale(1) rotateZ(0);
      -o-transform: scale(1) rotateZ(0);
   }

   50% {
      transform: scale(1) rotateZ(180deg);
      -webkit-transform: scale(1) rotateZ(180deg);
      -moz-transform: scale(1) rotateZ(180deg);
      -ms-transform: scale(1) rotateZ(180deg);
      -o-transform: scale(1) rotateZ(180deg);
   }

   100% {
      transform: scale(1) rotateZ(360deg)
   }
}



@keyframes jello-vertical {
   0% {
      transform: scale3d(1, 1, 1)
   }

   30% {
      transform: scale3d(.75, 1.25, 1)
   }

   40% {
      transform: scale3d(1.25, .75, 1)
   }

   50% {
      transform: scale3d(.85, 1.15, 1)
   }

   65% {
      transform: scale3d(1.05, .95, 1)
   }

   75% {
      transform: scale3d(.95, 1.05, 1)
   }

   100% {
      transform: scale3d(1, 1, 1)
   }
}


/* Establecer el contenido del icono */
.list-pre-orders-inputs input[type="radio-categories"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (✔) */
   font-size: 14px;
   color: rgb(255, 255, 255);
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-pre-orders-inputs label {
   position: relative;
   padding-left: 0px;
}

.list-pre-orders-inputs input[type="radio-categories"]+label:before {
   position: absolute;
   right: -9px;
   top: 1%;
}

.list-group-item {
   background-color: var(--blanco);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}



/*title: checkbox */
.list-pre-orders-inputs input[type="checkbox"] {
   display: none;
}

.list-pre-orders-inputs input[type="checkbox"]+label:before {
   content: "";
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid #ffffff28;
   margin-right: 10px;
}

.list-pre-orders-inputs input[type="checkbox"]:checked+label:before {
   background-color: #007bff;
}

/* Establecer el contenido del icono */
.list-pre-orders-inputs input[type="checkbox"]:checked+label:before {
   content: "\2713";
   /* Código unicode del icono (por ejemplo, ✔) */
   font-size: 14px;
   /* Ajustar el tamaño del icono según sea necesario */
   color: rgb(255, 255, 255);
   /* Color del icono */
   text-align: center;
   line-height: 18px;
   /* Centrar verticalmente el icono */
}

.list-pre-orders-inputs label {
   position: relative;
   padding-left: 0px;
}

.list-pre-orders-inputs input[type="checkbox"]+label:before {
   position: absolute;
   right: -9px;
   top: 1%;
}

.list-group-item {
   background-color: var(--blanco);
   border: 1px solid #cfcfcf73;
   border-radius: 1px;
   -webkit-border-radius: 1px;
   -moz-border-radius: 1px;
   -ms-border-radius: 1px;
   -o-border-radius: 1px;
}

/* 
.text-categories-menu-slider {
   font-size: 12px;
  
   display: inline-block;
    overflow: hidden;
   white-space: nowrap;
} */



/* Effect Slide  Letters  */
.list-group-item-check:checked+.list-group-item .text-categories-menu-slider {
   width: 200px;
   animation: marquee 4s infinite linear;
   -webkit-animation: marquee 4s infinite linear;
}


@keyframes marquee {
   0% {
      transform: translateX(0);
   }

   100% {
      transform: translateX(-100%);
   }
}


/* Cards Menu  */
.ecommerce-menu-card {
   margin: 0px 5px;

}

.img-ecommerce-menu-card {
   width: 80px;
   height: 80px;
   object-fit: cover;
}

.img-notification-card {
   width: 100%;
   height: 120px;
   object-fit: cover;
}


.title-ecommerce-menu-card {
   font-size: 18px;

   font-weight: 800;
}

.description-ecommerce-menu-card {
   font-size: 12px;
   font-weight: 500;
   line-height: 1.5;
   /* Altura de línea, ajusta según sea necesario */
   max-height: 3em;
   /* 2 líneas * altura de línea */
   overflow: hidden;
   position: relative;

}

.value-ecommerce-menu-card {
   font-size: 20px;
   font-weight: 800;
   color: #0fc718;
   letter-spacing: 1px;
}

.ecomerce-container-product-0 {

   height: 90vh;

}

.img-ecommerce-menu-product {

   width: 100%;
   height: 140px;
   object-fit: cover;

}

.ecomerce-container-info {

   margin: 0px 5px;

}

.ecomerce-container-info-title {
   font-size: 18px;
}

.ecomerce-container-info-description {
   font-size: 12px;
}

.title-ecommerce-info {
   font-size: 18px;

}

.description-ecommerce-info {
   font: 15px;

}

.btn-add-product {
   color: white;
   background-color: #007bff;
   background: #085ec5;
   background: -webkit-linear-gradient(to top #085ec5, #0072ff);
   background: linear-gradient(to top, #085ec5, #0072ff);
   font-size: 18px;
   padding: 5px;
   font-weight: 800;
}

.btn-add-product:active {
   color: white;
   background-color: #007bff;
   padding: 5px;
}

.btn-add-product:hover {
   color: white;
   background-color: #007bff;
   padding: 5px;
}

/* Btns  */
.btn-header-ecommerce-product {
   background-color: #00000048;
   color: #fff;
   padding: 5px 10px;
   margin: 2px 4px;
}

.btn-header-ecommerce-product :focus {
   color: #fff;
   padding: 5px 10px;
   margin: 2px 4px;
   background: #055aaf;
   background: -webkit-linear-gradient(to top, #0357be, #0baad6);
   background: linear-gradient(to top, #0357be, #0baad6)
}

.btn-header-ecommerce-product:active {
   color: #fff;
   background: #055aaf;
   background: -webkit-linear-gradient(to top, #0357be, #0baad6);
   background: linear-gradient(to top, #0357be, #0baad6);
   transform: scale(0.85);
   -webkit-transform: scale(0.85);
   -moz-transform: scale(0.85);
   -ms-transform: scale(0.85);
   -o-transform: scale(0.85);
}

.item-value-product {
   color: #fff;
   font-size: 20px;
   font-weight: 800;
}

.item-count-product {
   font-size: 25px;
   font-weight: 800;
}

.modal-value-product {
   color: #000000;
   font-size: 20px;
   font-weight: 800;
}

.img-ecommerce-menu-product-preview {
   width: 100%;
   /* Asegura que la imagen ocupe todo el ancho del contenedor */
   height: auto;
   /* Mantiene la altura proporcional al nuevo ancho */
   object-fit: contain;
   /* Ajusta la imagen dentro del contenedor sin deformarla */
}

.button-press {

   cursor: pointer;
   transition: transform 0.1s ease;
}

.button-press:active {
   transform: scale(0.95);
}


/* iNPUST  Radio-categories  */


.card-categories-input {
   border-radius: var(--card-radius);
   position: relative;

}


.card-tab-categories-input {
   border-radius: var(--card-radius);
   position: relative;

}


.card-active-tab-categories-input {
   border-radius: var(--card-radius);
   background-color: #0072ff;
   color: var(--divTextColor);
   border: 5px solid #0072ff;

}

.card-categories-input:hover {
   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio-categories {
   font-size: inherit;
   margin: 0;
   position: absolute;
   right: calc(var(--card-padding) + var(--radio-categories-border-width));
   top: calc(var(--card-padding) + var(--radio-categories-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
   .radio-categories {
      appearance: none;
      background: #fff;
      border: var(--radio-categories-border-width) solid var(--color-gray);
      border-radius: 50%;
      cursor: pointer;
      height: var(--radio-categories-size);
      outline: none;
      transition: background 0.2s ease-out, border-color 0.2s ease-out;
      width: var(--radio-categories-size);
      display: none;
   }

   .radio-categories::after {
      border: var(--radio-categories-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: "";
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
   }

   .radio-categories:checked {
      background: #0072ff;
      border-color: #0072ff;
      display: none;
   }

   .card-categories-input:hover .radio-categories {
      border-color: var(--color-dark-gray);
   }

   .card-categories-input:hover .radio-categories:checked {
      border-color: var(--color-green);
   }
}

.plan-details-categories-input {
   color: var(--btnColor);
   border: 1px solid rgba(207, 207, 207, 0.253);
   border-radius: 20px;
   cursor: pointer;
   padding: var(--card-padding);
   transition: border-color 0.2s ease-out;


}


.plan-details-categories-input-tab {
   color: var(--btnColor);
   border-radius: 20px;
   cursor: pointer;
   padding: 40px 20px;
   transition: border-color 0.2s ease-out;


}

.radio-categories:checked~.plan-details-categories-input-tab {
   background-color: #0072ff;
   color: var(--divTextColor);
   border: 5px solid #0072ff;

}


.card-categories-input:hover .plan-details-categories-input {
   border-color: var(--color-dark-gray);
}

.radio-categories:checked~.plan-details-categories-input {
   background-color: #0072ff;
   color: #fff;
   border: 1px solid #0072ff;

}

.radio-categories:checked.plan-type {
   color: #fff;

}

.radio-categories:focus~.plan-details-categories-input {
   box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio-categories:disabled~.plan-details-categories-input {
   color: var(--color-dark-gray);
   cursor: default;
}

.radio-categories-categories-input:disabled~.plan-details-categories-input .plan-type {
   color: var(--color-dark-gray);
}

.card-categories-input:hover .radio-categories:disabled~.plan-details-categories-input {
   border-color: var(--color-gray);
   box-shadow: none;
}

.card-categories-input:hover .radio-categories:disabled {
   border-color: var(--color-gray);
}

.plan-type-radio-categories {
   font-size: 15px;
   font-weight: bold;
   padding: 2px 4px;
}

.plan-cost {
   font-size: 2.5rem;
   font-weight: bold;
   padding: 0.5rem 0;
}

.slash {
   font-weight: normal;
}

.plan-cycle {
   font-size: 2rem;
   font-variant: none;
   border-bottom: none;
   cursor: inherit;
   text-decoration: none;
}

.hidden-visually {
   border: 0;
   clip: rect(0, 0, 0, 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   white-space: nowrap;
   width: 1px;
}

.container-list-categories {
   overflow: scroll;
   overflow-x: auto;
   white-space: nowrap;
   position: relative;
}

.icon-sad-lg {
   font-size: 7.5rem;
}



/*! Card Segments of Product */

.card-segments {
 
  border-radius: 20px;
  position: relative;
  width: 120px;
}


.radio-segments {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio-segments {
    appearance: none;
 display: none;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: var(--radio-size);
  }
  .radio-segments::after {
    border: var(--radio-border-width) solid #fff;
    border-top: 0;
    border-left: 0;
    content: "";
    display: block;
    height: 0.75rem;
    left: 25%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    width: 0.375rem;
  }
  .radio-segments:checked {
   display: block;
    background: #0072ff;
    border-color: #0072ff;
  }

  .card-segments:hover .radio-segments {
    border-color: var(--color-dark-gray);
  }
  .card-segments:hover .radio-segments:checked {
    border-color:#0072ff;
  }
}


.card-segments:hover .plan-details-segments {
  border-color: #0072ff;
}

.radio-segments:checked ~ .plan-details-segments {
   color: #0072ff;
   border: 2px solid #0072ff ;

}

.radio-segments:focus ~ .plan-details-segments {
  box-shadow: 0 0 0 2px var#0072ff;
}

.radio-segments:disabled ~ .plan-details-segments {
  color: #0072ff;
  cursor: default;
}

.radio-segments:disabled ~ .plan-details-segments .plan-type {
  color: var(--color-dark-gray);
}

.card-segments:hover .radio-segments:disabled ~ .plan-details-segments {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card-segments:hover .radio-segments:disabled {
  border-color: var(--color-gray);
}

.img-details-segments{
   width: 100px;
   height: 100px;
   object-fit: cover;
}

.plan-details-segments {
    background-color: #fff;
  border: 1px solid #e2e2e2c2;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 5px;
  transition: border-color 0.2s ease-out;
  
}

.plan-type-segments {
  font-size: 15px;
  font-weight: bold;
  line-height: 1em;
  white-space: normal;

}

.plan-cost-segments {
  font-size: 18px;
  font-weight: bold;
  padding: 0.5rem 0;
}


.plan-cycle-segments {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}