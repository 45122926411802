.btn-restaurant {
    font-size: 15px;
    border: 1px solid #a09e9e50;
}

.btn-restaurant-icon {
    color: #ff730a;
    /* background: linear-gradient(to top, #ff730a, #ffd8a5);
    background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-size: 20px;
}


.btn-restaurant-title {
    color: #ffffff;
    font-size: 15px;
}

.btn-restaurant-subtitle {
    color: #ffffff;
    font-size: 12px;
}



/* Estilos del fondo del modal */
.modal-overlay-ecom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Estilos del contenedor del modal */
.modal-content-ecom {
    width: 95%;
    height: 95%;
    background-color: white;
    border-radius: 15px;
    position: relative;
    /* display: flex;
  justify-content: center;
  align-items: center; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease;
}

/* Estilos del botón de cierre */
.close-button-ecom {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.2s ease;
}

.close-button-ecom:hover {
    color: red;
}

/* Animación para el modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}


.modal-content-ecom-restaurant {
    width: 100%;
    height: 520px;
}

@media (width <=480px) {
    .modal-content-ecom-restaurant {
        width: 100%;
        height: 90%;
    }
}

@media (width <=380px) {
    .modal-content-ecom-restaurant {
        width: 100%;
        height: 88%;
    }
}


.img-restaurant {
    width: 100%;
    height: 120px;
    object-fit: cover;
}

:root {
    --card-line-height: 1.2em;
    --card-padding: 5px;
    --card-radius: 0.5em;
    --color-green: #0072ff;
    --color-gray: #e2ebf6;
    --color-dark-gray: #c4d1e1;
    --radio-border-width: 2px;
    --radio-size: 1.5em;
}

.card-restaurant {
    background-color: #fff;
    border-radius: var(--card-radius);
    position: relative;
    display: block;
    margin-top: 10px;
}

.card-restaurant:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
    font-size: inherit;
    margin: 0;
    display: none;
    position: absolute;
    right: calc(var(--card-padding) + var(--radio-border-width));
    top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .radio {
        appearance: none;
        background: #fff;
        border: var(--radio-border-width) solid var(--color-gray);
        border-radius: 50%;
        cursor: pointer;
        height: var(--radio-size);
        outline: none;
        transition: background 0.2s ease-out, border-color 0.2s ease-out;
        width: var(--radio-size);
    }

    .radio::after {
        border: var(--radio-border-width) solid #fff;
        border-top: 0;
        border-left: 0;
        content: "";
        display: block;
        height: 0.75rem;
        left: 25%;
        position: absolute;

        top: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
        width: 0.375rem;
    }

    .radio:checked {
        display: inline;
        background: var(--color-green);
        border-color: var(--color-green);
    }

    .card-restaurant:hover .radio {
        border-color: var(--color-dark-gray);
    }

    .card-restaurant:hover .radio:checked {
        border-color: var(--color-green);
    }
}

.plan-details {
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: var(--card-radius);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: var(--card-padding);
    transition: border-color 0.2s ease-out;
}

.card-restaurant:hover .plan-details {
    border-color: var(--color-dark-gray);
}

.radio:checked~.plan-details {
    border: 2.5px solid var(--color-green);
}

.radio:focus~.plan-details {
    box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.plan-details {
    color: var(--color-dark-gray);
    cursor: default;
}

.radio:disabled~.plan-details .plan-type {
    color: var(--color-dark-gray);
}

.card-restaurant:hover .radio:disabled~.plan-details {
    border-color: var(--color-gray);
    box-shadow: none;
}

.card-restaurant:hover .radio:disabled {
    border-color: var(--color-gray);
}

.plan-type {
    color: #020202;
    font-size: 15px;
    font-weight: 700;
    line-height: 1em;
    text-align: left;
    margin-top: 2px;
}

.plan-description {
    color: #767676a3;
    font-size: 10px;
    font-weight: 500;
    line-height: 1em;
    text-align: left;
    margin-top: 2px;

}

.plan-cost {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0.5rem 0;
}

.slash {
    font-weight: normal;
}

.plan-cycle {
    font-size: 2rem;
    font-variant: none;
    border-bottom: none;
    cursor: inherit;
    text-decoration: none;
}

.hidden-visually {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.badge-position {

    left: 4%;
    top: 6%;
}

