.bg-header-profile {
   margin: 4px 0px;
   width: 100%;
   /* height: 150px; */
   background: #00c6ff;
   background: -webkit-linear-gradient(to top, #0072ff, #00c6ff);
   background: linear-gradient(to top, #0072ff, #00c6ff);
   border-radius: 15px;
   -webkit-border-radius: 15px;
   -moz-border-radius: 15px;
   -ms-border-radius: 15px;
   -o-border-radius: 15px;
}

.bg-header-profile small {
   margin-left: 10px;
   color: #fff;
   font-size: 15px;
   font-weight: 800;
}


.bg-header-profile h3 {
   margin-left: 10px;
   color: #fff;
   font-size: 25px;
   font-weight: 900;
}


.bg-header-orders {
   margin: 4px 0px;
   width: 100%;
   /*height: 150px;
   */
   background: #00c6ff;
   background: -webkit-linear-gradient(to top, #0072ff, #00c6ff);
   background: linear-gradient(to top, #0072ff, #00c6ff);
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
}

.bg-header-orders span {
   margin-left: 10px;
   color: #fff;
   font-size: 20px;
   font-weight: 800;
}


.header-profile-banner {
   margin: 0px 0px;

}

.header-profile-text-banner {
   width: 100%;
   height: 250px;
   top: 0;
   background: rgba(0, 0, 0, 0.575);
   backdrop-filter: blur(1px);
   -webkit-backdrop-filter: blur(10px);


}

.header-profile-img {
   object-fit: cover;
   background-position: center;
   width: 100%;
   height: 250px;
}

.header-profile-img-logo {
   background-position: center;
   width: 270px;
   height: 200px;
}

.header-profile-banner-login {
   object-fit: cover;
   background-position: center;
   width: 100%;
   height: 120px;
}