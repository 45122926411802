
.bg-blur-orders {
   background-color: #068fff;
   backdrop-filter: blur(5px); 
}

.bg-header-orders{
   background-color: #068fff;
   backdrop-filter: blur(5px);
}

.bg-header-orders h2 {
   color: #fff;
   font-size: 30px;
   font-weight: 700;
   letter-spacing: 2px;
}
.bg-header-orders small {
   color: #fff;
   font-size: 20px;
   font-weight: 700;
   letter-spacing: 1px;
}
/* Cards orders  */
.ecommerce-orders-card{
   border: 1px solid #adb5bd85;
   
   }
   
   .img-ecommerce-orders-card {
      width: 10px;
      height: 10px;
      object-fit: contain;
   }
   
   
   .title-ecommerce-orders-card {
      font-size: 18px;
      width: 100px;
      font-weight: 800;
   }
   .description-ecommerce-orders-card {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5; /* Altura de línea, ajusta según sea necesario */
      max-height: 3em; /* 2 líneas * altura de línea */
      overflow: hidden;
      position: relative;
      
   }
   
   .value-ecommerce-orders-card {
      font-size: 18px;
      font-weight: 700;
      color: #2ab731;
      letter-spacing: 1px;
   }

   .mark{
padding: 0px 1px;
   }